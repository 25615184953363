<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-flex uk-flex-between uk-margin">
            <div class="uk-flex uk-flex-left">
                <div class="uk-inline">
                    <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                    <input class="uk-input" @keyup.enter="(e) => changeMeta(e, 'company_name')" type="text" placeholder="Search company name">
                </div>
                <div class="uk-flex uk-margin-left">
                    <button class="uk-button uk-button-default uk-width-expand" style="background:white" type="button">{{filterDate}}</button>
                        <div id="date-dropdown" uk-dropdown="mode: click;pos: right-top">
                            <ul class="uk-dropdown-grid uk-child-width-auto" uk-grid>
                                <div>
                                    <datetime
                                            id="start_date"
                                            input-class="uk-button"
                                            v-model="meta.start_date"
                                            :max-datetime="new Date().toISOString()"
                                            placeholder="Start Date"
                                            @select="getInitPageData()"
                                        ></datetime>
                                </div>
                                <template v-if="meta.start_date">
                                    <div class="uk-text-center uk-margin-auto-vertical">-</div>
                                    <div>
                                        <datetime
                                                id="end_date"
                                                input-class="uk-button"
                                                v-model="meta.end_date"
                                                :min-datetime="meta.start_date"
                                                :max-datetime="new Date().toISOString()"
                                                placeholder="End Date"
                                            ></datetime>
                                    </div>
                                    <div>
                                        <button class="uk-button uk-button-primary uk-width-auto" type="button" v-if="meta.start_date" @click="setDate()">Apply</button>
                                    </div>
                                </template>
                            </ul>
                        </div>
                    <button class="uk-button-small uk-button-danger uk-width-auto" type="button" v-if="meta.start_date" @click="resetDate()"><span uk-icon="icon: close"></span></button>
                </div>
            </div>
            <div class="uk-flex uk-flex-right">
                <button class="uk-button uk-button-primary uk-border-rounded" type="button" @click="downloadExcel">Download</button>
            </div>
        </div>
        <div class="uk-card uk-card-default">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr class="uk-text-left">
                            <th>Company</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Total Mitra</th>
                            <th>Active Mitra</th>
                            <th>Auto Assign Mitra</th>
                            <th>Inactive Mitra</th>
                        </tr>
                    </thead>
                    <loading-table v-if="isLoading" :colspan="6"/>
                    <tbody v-else-if="page_data.docs.length>0">
                        <tr v-for="(data, i) in page_data.docs" :key="i" class="uk-text-left">
                            <td>{{data.company_name}}</td>
                            <td>{{formatDate(data.start_date)}}</td>
                            <td>{{formatDate(data.end_date)}}</td>
                            <td>{{data.total_mitra}}</td>
                            <td>{{data.active_mitra}}</td>
                            <td>{{data.auto_assign_mitra}}</td>
                            <td>{{data.inactive_mitra}}</td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="6"/>
                </table>
            </div>
            <pagination
                :total-data="page_data.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import formatter from "@/utils/formatter";
import { excelDownloader } from '@/utils/helper';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import LoadingTable from '@/components/globals/tables/Loading';
import Pagination from '@/components/globals/Pagination';
import { Datetime } from 'vue-datetime';

export default {
    data(){
        return {
            meta: {
                limit: 50,
                page: 1,
                company_name: '',
                start_date: null,
                end_date: null
            },
            page_data: {docs: [], totalDocs: 0},
            isLoading: true,
            isEdit: false
        };
    },
    components: {
        EmptyTable,
        Pagination,
        LoadingTable,
        Datetime
    },
    watch: {
        meta() {
            this.getInitPageData();
        },
    },
    computed: {
        filterDate(){
            if (!this.meta.start_date) {
                return 'Filter Tanggal';
            } else if (!this.meta.end_date) {
                return formatter.dateComplete(this.meta.start_date);
            } else {
                return formatter.dateComplete(this.meta.start_date) + ' - ' + formatter.dateComplete(this.meta.end_date);
            }
        }
    },
    async mounted() {
        await this.getInitPageData();
    },
    methods: {
        ...mapActions({
            getData: 'report/getCompanyAttendanceTrackerReport',
        }),
        async getInitPageData(){
            this.isLoading = true;
            this.page_data = await this.getData(this.meta);
            this.isLoading = false;
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        formatDate(data) {
            if (data == null) {
                return '-';
            }
            return formatter.dateComplete(data);
        },
        async downloadExcel() {
            this.isLoading = true;
            const excelData = [];
            for (const item of this.page_data.docs) {
                excelData.push({
                    'Company': item.company_name,
                    'Start Date': this.formatDate(item.start_date),
                    'End Date': this.formatDate(item.end_date),
                    'Total Mitra': item.total_mitra,
                    'Active Mitra': item.active_mitra,
                    'Auto Assign Mitra': item.auto_assign_mitra,
                    'Inactive Mitra': item.inactive_mitra
                });
            }

            excelDownloader(excelData, 'Attendance Tracker Report.xls');
            this.isLoading = false;
        },
        async setDate() {
            await this.getInitPageData();
            await window.UIkit.dropdown('#date-dropdown').hide();
        },
        async resetDate() {
            this.meta.start_date = null;
            this.meta.end_date = null;
            await this.getInitPageData();
        },
    },
};
</script>
